<template lang="pug">
.help-center-content.p-2
  .help-center-recommended.pb-4.border-bottom.mb-4(v-if="hasTopBlock")
    om-heading.mb-4(h6) {{ $t('helpCenter.recommended') }}
    om-link(secondary withIconLeft @click="readAnalytics" v-if="learnMore")
      span {{ $t('helpCenter.learnMore') }}
      template(slot="left-icon")
        UilBookAlt.mx-3(size="1.5rem" color="#505763")
    .help-center-video.mb-4.cursor-pointer(@click="videoUrl" v-if="hasVideo")
      img(:src="videoData.thumbnail")
      .pl-3
        .help-center-video-title {{ videoData.title }}
        div {{ duration }}
  .help-center-main
    om-heading.mb-4(h6) {{ $t('helpCenter.title') }}
    om-link.mb-3(secondary withIconLeft @click="readArticle")
      span {{ $t('helpCenter.readArticle') }}
      template(slot="left-icon")
        UilBookAlt.mx-3(size="1.5rem" color="#505763")
    om-link(secondary withIconLeft @click="watchVideo")
      span {{ $t('helpCenter.watchVideo') }}
      template(slot="left-icon")
        UilPlayCircle.mx-3(size="1.5rem" color="#505763")
</template>
<script>
  import { UilBookAlt, UilMap, UilPlayCircle } from '@iconscout/vue-unicons';
  import { mapGetters } from 'vuex';

  export default {
    components: { UilBookAlt, UilMap, UilPlayCircle },
    data() {
      return {
        videoIds: {
          dashboard: {
            hu: '-fOgo1j_47M',
            en: 'yeh8nIiT8aI',
          },
          'code-insert': {
            hu: 'xtttXUAlU9s',
            en: 'K39lnwt_lDQ',
          },
        },
        videoData: null,
        learnMoreLinks: {
          analytics: {
            en: 'https://support.optimonk.com/hc/en-us/articles/5098278551186-Campaign-Analytics',
            hu: 'https://support.optimonk.hu/hc/hu/articles/14402942764562-Kamp%C3%A1ny-analitika',
          },
        },
      };
    },
    computed: {
      ...mapGetters(['getLocale']),
      duration() {
        const zeroFilledMinutes = `00${this.videoData.duration.minutes}`.slice(-2);
        const zeroFilledSeconds = `00${this.videoData.duration.seconds}`.slice(-2);
        return `(${zeroFilledMinutes}:${zeroFilledSeconds})`;
      },
      videoId() {
        const isCodeInsertPage = this.$route.path.includes('code-insert');
        const videoKey = isCodeInsertPage ? 'code-insert' : this.$route.name;
        if (this.videoIds[videoKey]) {
          return this.videoIds[videoKey][this.getLocale];
        }
        return null;
      },
      learnMore() {
        return this.learnMoreLinks?.[this.$route.name]?.[this.getLocale];
      },
      hasVideo() {
        return this.videoId && this.videoData;
      },
      hasTopBlock() {
        return this.hasVideo || this.learnMore;
      },
    },
    watch: {
      $route() {
        this.setVideoData();
      },
    },
    mounted() {
      this.setVideoData();
    },
    methods: {
      async setVideoData() {
        if (this.videoId) {
          this.videoData = await this.getYoutubeVideo(this.videoId);
        }
      },
      async getYoutubeVideo(videoId) {
        const { data } = await this.$axios.get('/youtube/video', { params: { videoId } });
        return data;
      },
      readArticle() {
        const huLink = 'https://support.optimonk.hu/hc/hu';
        const enLink = 'https://support.optimonk.com/hc/en-us';
        const redirectByLang = this.getLocale === 'en' ? enLink : huLink;
        return window.open(redirectByLang, '_blank');
      },
      watchVideo() {
        return window.open(
          'https://www.optimonk.com/getting-started-with-optimonk-course/',
          '_blank',
        );
      },
      videoUrl() {
        if (this.videoId) {
          return window.open(`https://www.youtube.com/watch?v=${this.videoId}`, '_blank');
        }
        return false;
      },
      readAnalytics() {
        window.open(this.learnMore, '_blank');
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .help-center-content
    .heading
      color: $om-gray-700
  .help-center-video-title
    white-space: normal!important
  .help-center-video
    color: $om-gray-600
    display: flex
    img
      border-radius: 4px
      max-height: 60px
</style>
