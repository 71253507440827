<template lang="pug">
popper(
  trigger="click"
  :options="{ placement: 'right-end' }"
  @created="popoverInstance = $event"
  transition="fade"
  enter-active-class="fade-enter-active"
  leave-active-class="fade-leave-active"
)
  .popper.brand-side-nav-profile-popper
    .brand-side-nav-profile-popper-content
      .brand-side-nav-profile-popper-content-top
        .brand-side-nav-profile-popper-content-top-img
          om-avatar(
            :img="getAvatarUrl"
            big
            :name="{ firstName: account.login.firstName, lastName: account.login.lastName }"
          )
        .ml-3.mb-2
          .font-weight-bold.font-size-0--9375 {{ getFullNameByLocale(account.login.firstName, account.login.lastName, account.locale) }}
          .font-size-0--875.brand-side-nav-profile-popper-gray.text-truncate {{ account.login.email }}
      .brand-side-nav-profile-popper-content-body
        .brand-side-nav-profile-popper-content-body-left
          profile-actions(@close="popoverInstance.doClose()")
        .brand-side-nav-profile-popper-content-body-right
          profile-infos
  .cursor-pointer(slot="reference")
    om-avatar(
      :img="getAvatarUrl"
      :name="{ firstName: account.login.firstName, lastName: account.login.lastName }"
    )
</template>

<script>
  import OmAvatar from '@/components/Avatar';
  import ProfileActions from '@/components/ProfileActions';
  import ProfileInfos from '@/components/ProfileInfos';
  import fullName from '@/mixins/fullName';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: { OmAvatar, ProfileActions, ProfileInfos },
    mixins: [fullName],

    data() {
      return {
        popoverInstance: null,
      };
    },

    computed: {
      ...mapState(['account']),
      ...mapGetters(['getAvatarUrl']),
    },
  };
</script>
