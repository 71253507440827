<template lang="pug">
nav.brand-side-nav(ref="sideNav")
  router-link.brand-side-nav-logo(:to="{ name: dashboardRoute }" ref="brandLogo")
    img(:src="customSquaredLogo || require('@/assets/admin/svg/clean-logo.svg')")

  .brand-side-nav-top(ref="menus")
    router-link.brand-side-nav-item.d-flex.flex-column(
      :to="{ name: item.route }"
      :class="utilityClasses(item.route)"
      v-for="item in mainMenuItems"
      :key="item.route"
      :ref="`menuItem`"
    )
      img.side-nav-icon(:src="require(`@/assets/admin/svg/sidebar-nav/${item.icon}.svg`)")
      span.item-title {{ item.title }}

    .more-menu-items(
      v-if="moreMenuItems.length"
      :class="{ active: moreActive, selected: moreMenuItemSelected }"
    )
      popper(
        trigger="click"
        :options="{ placement: 'right-end' }"
        transition="fade"
        enter-active-class="fade-enter-active"
        leave-active-class="fade-leave-active"
        @show="moreActive = true"
        @hide="moreActive = false"
        @created="moreMenuInstance = $event"
      )
        .popper.brand-tooltip-light.brand-tooltip-right.ml-n1
          more-menu-popper(:menuItems="moreMenuItems" @hideMoreMenu="hideMoreMenu")
        div(slot="reference")
          .brand-side-nav-item.cursor-pointer.d-flex.flex-column
            img(:src="require(`@/assets/admin/svg/sidebar-nav/more.svg`)")
            span.item-title {{ $t('sideNav.more') }}

  .brand-side-nav-bottom(ref="navBottom")
    popper(
      trigger="hover"
      :options="{ placement: 'right' }"
      transition="fade"
      enter-active-class="fade-enter-active"
      leave-active-class="fade-leave-active"
      v-if="!isSubUser"
    )
      .popper.brand-tooltip.brand-tooltip-light.brand-tooltip-right.ml-n1
        div {{ $t('whatsNew') }}
      div(slot="reference")
        .brand-side-nav-bottom-item.cursor-pointer.beamerTrigger
          img(:src="require(`@/assets/admin/svg/sidebar-nav/notification.svg`)")
    popper(
      trigger="hover"
      ref="helpMenu"
      :options="{ placement: 'right-end' }"
      transition="fade"
      enter-active-class="fade-enter-active"
      leave-active-class="fade-leave-active"
      @click.native="toggleHelpTooltip"
      @created="popoverInstance = $event"
      v-if="!isSubUser"
    )
      .popper.brand-tooltip.brand-tooltip-light.brand-tooltip-right.ml-n1(
        v-show="!helpPopperActive"
      )
        div {{ $t('help') }}
      div(slot="reference")
        popper.help-center-popper(
          trigger="click"
          :options="{ placement: 'right-end' }"
          transition="fade"
          enter-active-class="fade-enter-active"
          leave-active-class="fade-leave-active"
          @show="helpPopperActive = true"
          @hide="helpPopperActive = false"
        )
          .popper.brand-tooltip.brand-tooltip-light.brand-tooltip-right.ml-n1
            help-popper
          div(slot="reference")
            .brand-side-nav-bottom-item.cursor-pointer
              img(:src="require(`@/assets/admin/svg/sidebar-nav/help.svg`)")
    .brand-side-nav-bottom-item
      profile-popper
</template>

<script>
  import whiteLabelling from '@/mixins/whiteLabelling';
  import ProfilePopper from '@/components/Poppers/Profile';
  import HelpPopper from '@/components/Poppers/Help';
  import MoreMenuPopper from '@/components/Poppers/MoreMenu';
  import menuItems from '@/mixins/menuItems';
  import activeDomain from '@/mixins/activeDomain';
  import { mapState, mapGetters } from 'vuex';

  export default {
    components: { ProfilePopper, HelpPopper, MoreMenuPopper },
    mixins: [whiteLabelling, menuItems, activeDomain],
    data() {
      return {
        popoverComponentName: null,
        visible: null,
        popoverInstance: null,
        helpPopperActive: false,
        mainMenuRoutes: ['campaigns', 'subscribers', 'analytics', 'templates'],
        mainMenuRoutesAgency: ['agency_dashboard', 'template_manager', 'ambassador_dashboard'],
        moreActive: false,
        visibleRoutes: [],
      };
    },
    computed: {
      ...mapState(['partnerTypeService']),
      ...mapGetters(['customSquaredLogo', 'isSubUser', 'domains']),
      hasInactiveDomain() {
        return this.domains.some((domain) => this.isInactiveDomain(domain));
      },
      mainMenuItems() {
        const mainMenu = [];
        this.visibleRoutes.forEach((route) => {
          const mainMenuItem = this.items.find((item) => item.route === route);
          if (mainMenuItem) {
            mainMenu.push(mainMenuItem);
          }
        });
        return mainMenu;
      },
      moreMenuItems() {
        return this.items.filter((item) => !this.visibleRoutes.includes(item.route));
      },
      moreMenuItemSelected() {
        return this.moreMenuItems.some((item) => item.route === this.$route.name);
      },
    },

    mounted() {
      this.getWhiteLabelSettings();
      this.getDefaultVisibleMenus();

      window.addEventListener('resize', this.getVisibleMenuItems);
      this.getVisibleMenuItems();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getVisibleMenuItems);
    },
    methods: {
      toggleHelpTooltip() {
        this.popoverInstance.doClose();
      },
      utilityClasses(route) {
        return {
          [route]: true,
        };
      },
      getDefaultVisibleMenus() {
        this.visibleRoutes = [...this.mainMenuRoutes];
        if (
          this.partnerTypeService?.isResellerPartnerType() ||
          this.partnerTypeService?.isCustom()
        ) {
          this.visibleRoutes = [...this.mainMenuRoutesAgency];
        }
      },
      hideMoreMenu() {
        this.moreMenuInstance.doClose();
      },
      getVisibleMenuItems() {
        setTimeout(() => {
          this.getDefaultVisibleMenus();
          const menuItemHeight = this.$refs.menuItem[0]?.$el?.clientHeight || 64;
          const bottomNavHeight = this.$refs.navBottom.clientHeight;
          const logoHeight = this.$refs.brandLogo.$el.clientHeight;
          const sideNavHeight = this.$refs.sideNav.clientHeight;

          let minimumNeededHeight =
            this.visibleRoutes.length * menuItemHeight +
            menuItemHeight +
            logoHeight +
            bottomNavHeight;

          while (minimumNeededHeight > sideNavHeight && this.visibleRoutes.length) {
            this.visibleRoutes.pop();
            minimumNeededHeight -= menuItemHeight;
          }
        }, 300);
      },
    },
  };
</script>
<style lang="sass">
  .help-center-popper .popper
    width: 360px
  .brand-side-nav-item
    position: relative

  .brand-side-nav-item,
  .more-menu-items

    &:hover
      text-decoration: none

    .side-nav-icon
      height: 20px
      width: 20px

    .item-title
      color: white
      font-size: 10px
      font-weight: 500
      line-height: 16px
      display: block
      margin-top: 4px
      text-align: center
      padding: 0 2px

  .more-menu-items
    &.active .brand-side-nav-item,
    &.selected .brand-side-nav-item
      background-color: rgba(0, 0, 0, 0.15)
    .popper__arrow
      display: none
</style>
