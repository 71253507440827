<template lang="pug">
.more-menu-content
  template(v-for="menuItem in menuItems")
    om-link(
      secondary
      withIconLeft
      @click="goToMenu(menuItem.route)"
      :key="`more-menu-${menuItem.route}`"
    )
      span.title {{ menuItem.title }}
      template(slot="left-icon")
        component(:is="`${toKebabCase(menuItem.route)}-menu`")
</template>

<script>
  import ExperimentsMenu from '@/assets/admin/svg/sidebar-nav/Experiments.vue';
  import TemplateManagerMenu from '@/assets/admin/svg/sidebar-nav/TemplateManager.vue';
  import AnalyticsMenu from '@/assets/admin/svg/sidebar-nav/Analytics.vue';
  import CampaignsMenu from '@/assets/admin/svg/sidebar-nav/Campaigns.vue';
  import AgencyDashboardMenu from '@/assets/admin/svg/sidebar-nav/PartnerDashboard.vue';
  import AmbassadorDashboardMenu from '@/assets/admin/svg/sidebar-nav/Referral.vue';
  import SubscribersMenu from '@/assets/admin/svg/sidebar-nav/Subscribers.vue';
  import TemplatesMenu from '@/assets/admin/svg/sidebar-nav/Templates.vue';

  export default {
    components: {
      ExperimentsMenu,
      TemplateManagerMenu,
      AnalyticsMenu,
      CampaignsMenu,
      AgencyDashboardMenu,
      AmbassadorDashboardMenu,
      SubscribersMenu,
      TemplatesMenu,
    },
    props: {
      menuItems: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      goToMenu(route) {
        this.$emit('hideMoreMenu');
        this.$router.push({ name: route });
      },
      toKebabCase(str) {
        return str.replace(/_/g, '-');
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .more-menu-content
    border: 1px solid $om-gray-300
    border-radius: 4px
    .brand-link-element
      &:hover
        background: $om-gray-200
      padding: 6px 16px
      .title
        line-height: 16px
        font-size: 12px
        padding-right: 8px
      .brand-link-element-icon
        padding: 8px
        svg
          width: 20px
</style>
