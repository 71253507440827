import { ONE_DAY_IN_MS } from '@/utils/constant';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isShopifyDomain']),
  },
  methods: {
    isInactiveDomain(domain) {
      const { domain: domainName, v3LastRequestDate } = domain;
      return (
        !this.isShopifyDomain(domainName) &&
        (!v3LastRequestDate ||
          new Date(v3LastRequestDate).getTime() < new Date().getTime() - ONE_DAY_IN_MS)
      );
    },
  },
};
